import request from "@/utils/request";

export function submitOrder(data) {
    return request.post("/order/order/saveOrder",data);
}

export function submitTrade(data) {
    return request.post("/pay/ali/pay/trade",data);
}

export function submitTradeWeb(data) {
    return request.post("/pay/ums/pay/web/trade",data);
}

export function wcplace(data) {
    return request.post("/pay/wc/pay/place",data);
}

export function submitTransfer(data) {
    return request.post("/pay/payTransfer/saveTransfer",data);
}

export function OrderNumber(orderNumber) {
    return request.get("/order/order/orderByOrderNumber/" + orderNumber);
}

export function expired(orderNumber) {
    return request.get("/pay/ali/pay/is/expired/" + orderNumber);
}

export function weresult(outTradeNo) {
    return request.get("/pay/wc/pay/query/result/" + outTradeNo);
}

export function listAddress() {
    return request.get("/order/address/listAddressByCustomer");
}

export function vevifyApply(outTradeNo) {
    return request.get("/pay/ali/pay/query/result/" + outTradeNo);
}

export function addEditAddress(data) {
    return request.post("/order/address/saveOrUpdateAddress" , data);
}

export function submitAddress(data) {
    return request.post("/order/address/saveOrderAddress" , data);
}

export function defaultAddress(id) {
    return request.put("/order/address/defaultAddress/" + id);
}

export function listInvoice() {
    return request.get("/order/invoice/listCustomerInvoice");
}

export function OrderPay(orderNumber) {
    return request.get("/order/order/updateOrderPay/" + orderNumber);
}

export function resultPay(outTradeNo) {
    return request.get("/pay/ums/pay/web/query/final/result/" + outTradeNo);
}

export function saveInvoice(data) {
    return request.post("/order/invoice/saveCustomerInvoice",data);
}

export function saveOrderInvoice(data) {
    return request.post("/order/invoice/saveOrderInvoice",data);
}

export function trade(data) {
    return request.post("/pay/ums/pay/trade",data);
}

export function outTradeNo(outTradeNo) {
    return request.get("/pay/ums/pay/query/result/" + outTradeNo);
}

export function orderTrans(orderNumber) {
    return request.get("/order/order/trans/" + orderNumber);
}

export function defaultInvoice(id) {
    return request.get("/order/invoice/defaultInvoice/" + id);
}

export function getFollow() {
    return request.get("/customer/getFollowData");
}

export function billdetail(sellNumber) {
    return request.get("/order/sale/one/query/detail/" + sellNumber);
}

export function confirm(data) {
    return request.post("/order/sale/one/confirm",data);
}

export function sftPlace(data){
    return request.post("/pay/sft/web/pay/place",data);
}

/*export function sftPay(data){
    return request.post("",data,{contentType:"x-www-form-urlencoded"});
}*/
